import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youfolio.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/folio_1.svg"
import HeaderImg from "../../assets/img/youFolio/Img1.png"
import Icon1 from "../../assets/img/youFolio/Icon1.png"
import Icon2 from "../../assets/img/youFolio/Icon2.png"
import Icon3 from "../../assets/img/youFolio/Icon3.png"
import Icon4 from "../../assets/img/youFolio/Icon4.png"
import Icon5 from "../../assets/img/youFolio/Icon5.png"
import Icon6 from "../../assets/img/youFolio/Icon6.png"
import Icon7 from "../../assets/img/youFolio/Icon7.png"
import Icon8 from "../../assets/img/youFolio/Icon8.png"
import Icon9 from "../../assets/img/youFolio/Icon9.png"
import Icon10 from "../../assets/img/youFolio/Icon10.png"
import Icon11 from "../../assets/img/youFolio/Icon11.png"
import Icon12 from "../../assets/img/youFolio/Icon12.png"
import GroupImg1 from "../../assets/img/youFolio/Img2.png"
import GroupImg2 from "../../assets/img/youFolio/Img3.png"
import TickIcon from "../../assets/img/youFolio/tick.svg"
import SingleForm from "../../components/singleForm"

const YouMAPage = () => {
  return (
    <div>
      <SEO title="Youfolio" keywords={[`youpal`, `youpal group`, `youFolio`]} />
      <div className="youFolioMain products">
        <section className="container">
          <div className="row">

            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                Organise and distribute your media files
                </h2>
              </div>
            </div>

            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
              Youfolio digital asset management is an application for storing, sharing, and organising photos, videos and audio into one place in one place. It's one of the must-haves in your digital environment. Centralising all your files let you manage them efficiently and get the maximum value from them.
              </p>
            </div>

            <div className="col-12 order-4 order-md-3">
              <Link to="/products/request-demo">
                <button type="button" className="btn cta btn-primary px-5 mt-5 productButton d-block mx-auto">
                  <span>Free demo</span>
                </button>
              </Link>
            </div>

            <div className="col-12 order-2 order-md-4">
              <img className="headerImg" src={HeaderImg} />
            </div>

          </div>
        </section>

        <section className="container solutionsSection">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            Our solution for your business
            </h3>
            <p className="internalNarrowCol">
            Youfolio saves your team members' precious time and connects all your company materials by keeping them under the same roof. Besides storing a massive amount of digital assets, it also takes care of your organisation's security and internal accessibility. Basically, everybody in your team will easily find the media files they need, and you will have access to all digital assets as a manager.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Find your files</h4>
              <p>
              Collecting all your media files in one place isn't enough for efficiency. You can do it yourself without a DAM solution, as well, right? This is the point where Youfolio’s filtering options come into play. It helps you to find exactly what you're looking for in a few seconds.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Access control</h4>
              <p>
              Confidentiality is essential, and we are committed to providing you with the necessary options to keep it under control. Set access permissions for various users as you wish to make sure everybody can access all they need. Not more, not less.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Internal & external</h4>
              <p>
              Sharing options inside Youfolio let you share your assets both internally and externally. There're also some other additional features, such as setting up expiration dates and tracking sharing history.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon4} />
              </div>
              <h4>Format flexibility</h4>
              <p>
              Downloading digital assets in the format you wish fastens the asset creation process. You can download assets in premodified formats to ensure consistency. Youindex also offers some editing functions.
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection analyticsSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                ANALYTICS
                </div>
                <h3 className="text-left">Use case examples</h3>
                <p>
                Digital asset management can be used in many ways, according to the situation. Here are two use case examples showing how Youfolio can help your business.
                </p>
                <div className="row">
                    <div className="col">
                        <div className="squareHolder"><img src={Icon5} /></div>
                        <h4>Centralization</h4>
                        <p>
                        With DAM, different department members can use the same asset instead of downloading it over and over again. 
                        </p>
                    </div>
                    <div className="col">
                        <div className="squareHolder"><img src={Icon6} /></div>
                        <h4>Distribution</h4>
                        <p>
                        Companies can optimise and decrease their asset-requesting time by utilizing a DAM. 
                        </p>
                    </div>
                </div>
                <div className="buttonArea">

            <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">FEATURES</div>
              <h3>How can you benefit from Youfolio?</h3>
              <p>
              Utilizing storage space, preventing loss of files, and organising your media files are only a few benefits of using Youfolio.
              </p>
              <div className="row mt-2 mt-md-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>Stimulating creativity by automating processes</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col sideContent">
                      <h4>Advanced search finds everything instantly</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>
                        Automatic picture formatting to publish for web, documents, Instagram, Facebook, LinkedIn
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon10} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Attaching watermarks onto pictures</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon11} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Access permissions based on copyright functions</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon12} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Automatic picture tagging based on facial recognition</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection collaborateSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">COLLABORATE</div>
                <h3>Collaborate internally and externally</h3>
                <p className="mt-0 mt-md-5">
                Youfolio is all about a smooth and fast collaboration environment. Optimising collaboration efficiency and speed frees a massive amount of your team's time, which can be invested in more important tasks.
                </p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Organise your digital assets
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Use labels and tags
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Share with team members
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Integrate for more
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Share externally
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouMAPage
